.section .section_title{
    position: relative;
    display: inline-block;
    padding: 0 3px;
}

.section .section_title > h6{
    font-size: 18px;
    font-weight: 600;
    position: relative;
    margin: 0;
}

.underline {
    position: absolute;
    height: 7px;
    width: 100%;
    bottom: 0;
    opacity: 0.8;
    left: 0;
    background-color: var(--main-color);
    transform: rotate(3deg);
}

.section .aboutme_text{
    color: darkslategrey;
    font-size: smaller;
    line-height: 22px !important;
    white-space: pre-line !important;
    text-align: center;
}

.service {
    height: 100%;
    text-align: center;
}

.service .service_title{
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 35px !important;
    text-align: center;
}

.service .service_description{
    color: #989898 !important;
    border-color: var(--main-color) !important;
    margin-right: 5px !important;
    text-align: center;
}

.service_icon > .MuiSvgIcon-root {
    font-size: 46px !important;
    margin-left: 0 !important;
    font-weight: 300 !important;
    color: darkslategrey !important;
}

.resume_timeline .timeline_content .timeline_title {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.resume_timeline .timeline_content .timeline_date{
    color: darkslategrey;
}

.resume_timeline .timeline_content .timeline_description {
    color: gray;
    font-size: small;
    margin-top: 6px;
}

.resume_container{
    padding-right: 999px !important;
}

.skill {
    border-radius: 8px;
    padding: 20px;
    /* box-shadow: 0px 0px 40px 0px rgba(4,6,4,0.08) !important; */
    min-height: 186px;
    height: 100%;
}

.skill .skill_title{
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 35px !important;
}

.skill .skill_description{
    display: flex;
    align-items: center;
    color: #525151 !important;
    font-weight: 500 !important;
    line-height: 18px !important;
}

.skill .skill_description .timeline_dot{
    border-color: var(--main-color);
    padding: 2px !important;
    margin-right: 5px !important;
}

.graybg{
    background-color: #fafafa ;
}

.p_50{
    padding: 40px !important;
}
