.timeline {
    padding: 0 !important;
}

.timeline .timeline_header{
    padding-top: 7px;
}

.timeline .timeline_firstItem{
    min-height: 80px !important;
}

.timeline .MuiTimelineItem-root {
    min-height: 0px;
}

.timeline .MuiTimelineItem-missingOppositeContent::before{
    display: none;
}

.timeline .timeline_dot_header{
    color: black;
    background-color: var(--main-color);
    font-size: small;
    padding: 10px !important;
}

.timeline .MuiTimelineConnector-root{
    background-color: #eee !important;
}

.timeline .timeline_dot{
    color: black;
    border-color: var(--main-color);
    font-size: small;
    padding: 2px !important;
}

.timeline .timeline_dot_header > .MuiSvgIcon-root {
    font-size: 20px;
    color: darkslategrey;
}

.timeline .separator_padding{
    padding-left: 18px !important;
}