.contactInfo_items {
    font-size: 14px !important;
    color: #787878;
    line-height: 34px !important;
}

.contactInfo_items > span {
    color: black;
    font-weight: 500;
}

.contactInfo_socialsContainer {
    margin-top: 24px !important;
}

.contactInfo_socials > a > .MuiSvgIcon-root{
    font-size: 16px !important;
    margin-right: 10px;
    color: black;
}