:root {
    --main-color: lightblue;
}

.container_shadow {
    box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
    -webkit-box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
}

.p_30 {
    padding: 30px !important;
}

.top_30 {
    margin-top: 30px !important;
}

.pb_45 {
    padding-bottom: 45px !important;
}

.top_60{
    margin-top: 60px;
}

.mb_30{
    margin-bottom: 30px !important;
}

.right {
    margin-left: 15px;
}

@media (max-width: 991px){
    .top_60{
        margin-top: 10px;
    }
}


.main_content{
    background-color: white;
    border-radius: 6px;
    --webkit-border-radius: 6px;
    margin-bottom: 30px;
}

.section {
    padding-left: 30px;
    padding-right: 30px;
}

.pt_30{
    padding-top: 30px;
}